.vc-container {
    position: relative;
}

:-webkit-full-screen .vc-video-container {
    margin: 10px;
}

:-moz-full-screen .vc-video-container {
    margin: 10px;
}

:-ms-fullscreen .vc-video-container {
    margin: 10px;
}

.vc-video-container {
    position: relative;
    min-height: 750px;
    max-height: 100%;
    height: 100%;
}

.vc-container .video-player {
    max-width: 100%;
    height: 100%;
}

.vc-container .video-controls {
    right: 0;
    left: 0;
    padding: 10px;
    position: absolute;
    bottom: 0;
    transition: all 0.2s ease;
    background-color: rgba(43, 51, 63, 0.7);
}

.vc-container .video-controls.hide {
    opacity: 0;
    pointer-events: none;
}

.vc-container .video-progress {
    position: relative;
    height: 3px;
    margin-bottom: 10px;
}

.vc-container progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    width: 100%;
    height: 3px;
    pointer-events: none;
    position: absolute;
    top: 0;
}

.vc-container progress::-webkit-progress-bar {
    background: rgba(115, 133, 159, 0.5);
    border-radius: 2px;
}

.vc-container progress::-webkit-progress-value {
    background: #ffffff;
    border-radius: 2px;
}

.vc-container progress::-moz-progress-bar {
    border: none;
    background: #ffffff;
}

.vc-container .seek {
    position: absolute;
    top: 0;
    width: 100%;
    cursor: pointer;
    margin: 0;
}

.vc-container .bottom-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vc-container .left-controls {
    display: flex;
    align-items: center;
    color: #fff;
}

.vc-container .seek:hover+.seek-tooltip {
    display: block;
}

.vc-container .seek-tooltip {
    display: none;
    position: absolute;
    top: -50px;
    margin-left: -20px;
    font-size: 12px;
    padding: 3px;
    content: attr(data-title);
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
}

.vc-container .volume-controls {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.vc-container .volume-controls input {
    width: 100px;
    opacity: 1;
    transition: all 0.4s ease;
}

.vc-container .volume-controls:hover input, .volume-controls input:focus {
    width: 100px;
    opacity: 1;
}

.vc-container .vc-btn {
    cursor: pointer;
    position: relative;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
}

.vc-container .vc-btn * {
    pointer-events: none;
}

.vc-container .vc-btn:hover::before {
    display: inline-block;
}

.vc-container .fullscreen-button {
    margin-right: 0;
}

.vc-container .pip-button {
    width: 40px;
}
.vc-container .pip-button svg {
    width: 20px;
    height: 20px;
}

.vc-container .input-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 3px;
    background: transparent;
    cursor: pointer;
}

.vc-container .input-range:focus {
    outline: none;
}

.vc-container .input-range::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    border-radius: 1.3px;
    -webkit-appearance: none;
    transition: all 0.4s ease;
}

.vc-container .input-range::-webkit-slider-thumb {
    height: 10px;
    width: 10px;
    border-radius: 16px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-left: -1px;
}

.vc-container .input-range:focus::-webkit-slider-runnable-track {
    background: transparent;
}

.vc-container .input-range.volume {
    height: 3px;
    background-color: #fff;
}

.vc-container .input-range.volume::-webkit-slider-runnable-track {
    background-color: transparent;
}

.vc-container .input-range.volume::-webkit-slider-thumb {
    margin-left: 0;
    height: 10px;
    width: 10px;
    background: #fff;
}

.vc-container .input-range::-webkit-slider-thumb:hover {
    height: 14px;
    width: 14px;
    transition: width 0.3s, height 0.3s;
}

.vc-container .input-range::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    border: 1px solid transparent;
    background: transparent;
    border-radius: 1.3px;
}

.vc-container .input-range::-moz-range-thumb {
    height: 14px;
    width: 14px;
    border-radius: 50px;
    border: none;
    background: #ffffff;
    cursor: pointer;
    margin-top: 5px;
}

.vc-container .input-range:focus::-moz-range-track {
    outline: none;
}

.vc-container .input-range.volume::-moz-range-thumb {
    border: 1px solid #fff;
    background: #fff;
}

.vc-container .time {
    font-size: 11px;
}

.vc-container .hidden {
    display: none;
}

.vc-container .right-controls {
    display: flex;
    align-items: center;
}

.vc-container .settings-button,
.vc-container .vc-svg {
    width: 40px;
    height: 20px;
    fill: #fff;
    stroke: #fff;
    cursor: pointer;
    color: #ffffff;
}

.vc-container .settings-box {
    display: flex;
    position: relative;
}

.vc-container .settings-button .vjs-icon-cog {
    font-size: 16px;
    color: #ffffff;
    line-height: 18px;
}

.vc-container .settings-box .settings-nav {
    border-top-color: rgba(43, 51, 63, 0.7);
    position: absolute;
    bottom: -5px;
    width: 100px;
    left: -30px;
    height: 0;
    margin-bottom: 1.5em;
    text-align: center;
    color: #ffffff;
    display: none;
    min-height: 14em;
    max-height: 25em;
}

.vc-container .settings-box .settings-nav ul {
    background-color: rgba(43, 51, 63, 0.7);
    position: absolute;
    width: 100px;
    bottom: 1.5em;
    display: block;
    padding: 0;
    margin: 0 0 7px;
}

.vc-container .settings-box .settings-nav li button {
    padding: 5px 0;
}

.vc-container .settings-box .settings-nav li {
    list-style: none;
    margin: 0;
    padding: 0.2em 0;
    line-height: 1.4em;
    text-align: center;
    text-transform: lowercase;
    font-size: 12px;
}

.vc-container .settings-box .settings-nav li.active {
    background-color: #ffffff;
}
.vc-container .settings-box .settings-nav li.active {
    color: black;
}

.vc-container .settings-box .settings-nav .zoom-slider {
    writing-mode: bt-lr;
    -webkit-appearance: slider-vertical;
    max-width: 100%;
    height: auto;
}

.vc-container .settings-box .settings-nav:hover,
.vc-container .settings-box:hover .settings-nav {
    display: block;
}

.vc-container .settings-box .playback-nav ul,
.vc-container .settings-box .playback-nav {
    width: 50px;
}

.vc-container .settings-box .playback-nav {
    left: -2px;
}

.vc-container .video-controls .v-rec-badge,
.vc-container .video-controls .reset-btn,
.vc-container .video-controls .v-rec-btn {
    display: none;
}

video.vc-video-mirror {
    transform: scale(-1, 1);
}

.video-mirror {
    color: white;
}

.video-mirror .vc-mirror-icon-right {
    display: none;
}

.video-mirror .vc-video-mirror .vc-mirror-icon-left {
    display: none;
}

.video-mirror .vc-video-mirror .vc-mirror-icon-right {
    display: inline-block;
}
