.video-player-container {
    position: relative;
    max-height: 100%;
    height: 750px;
}

.video-player {
    max-height: 100%;
    height: 750px;
    max-width: 800px;
    width: 100%;
    position: absolute;
}

:-webkit-full-screen .video-player {
    max-height: none;
}

:-moz-full-screen .video-player {
    max-height: none;
}

:-ms-fullscreen .video-player {
    max-height: none;
}

.video-player:-webkit-full-screen {
    max-height: none;
}

:fullscreen .video-player {
    max-height: none;
}

.main-options {
    background-color: rgba(43, 51, 63, 0.7);
    margin: 10px 0;
}
