.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-card {
    margin-top: 10% !important;
    width: 450px;
    max-width: 100%;
}
.login-head {
    text-align: center;
    padding-bottom: 10px;
    font-size: 26px;
}
.login-button {
    width: 100%;
}
.login-error {
    padding: 10px 0;
}
