.stream-index .video-list {
    margin-left: 5px;
}

@media (max-width: 1340px) {
    .stream-index > .ant-row {
        flex-flow: row wrap;
    }

    .stream-index .video-list {
        margin-left: 0;
        max-width: 800px;
    }

    .stream-index .video-list .ant-list-items {
        text-align: center;
    }
}
