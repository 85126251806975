.ant-modal-wrap.video-preview-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    overflow: visible;
}

.video-preview,
.video-preview, .react-resizable,
.video-preview .video-js.vjs-fluid {
    max-width: 100%;
}

.video-preview .ant-modal-body {
    padding: 5px;
    background-color: #000;
}

.video-preview .header-video-preview {
    cursor: move;
    height: 45px;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
}

.video-preview .header-video-preview:hover {
    background-color: rgba(43, 51, 63, 0.6);
}

.video-preview .react-resizable-handle {
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: se-resize;
    z-index: 200;
}

.video-preview .ant-modal-body .no-draggable {
    cursor: default;
}

.video-preview .ant-modal-close:hover {
    color: rgba(255, 255, 255, 0.75);
}

.video-preview .ant-modal-close {
    color: #ffffff;
}

.video-preview .video-js.vjs-fluid {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding-top: 0 !important;
    padding-bottom: 67px;
}

.video-js.vjs-fullscreen:not(.vjs-ios-native-fs) {
    height: 100% !important;
}

.video-preview .video-js .vjs-control-bar {
    bottom: 67px;
}

.video-preview .video-js video {
    width: 100%;
    padding-bottom: 67px;
}

.video-preview .video-settings {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

body.ant-scrolling-effect {
    overflow: visible !important;
}

.ant-slider-tooltip .ant-tooltip-inner {
    font-size: 10px;
    min-height: 22px;
    padding: 4px 8px;
    background-color: rgba(43, 51, 63, 0.7);
}
