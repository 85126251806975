.stream-controls .ant-form-item {
    margin-bottom: 0;
}

.stream-controls .v-navs {
    display: flex;
    align-items: center;
}

.stream-controls .v-settings-button {
    height: 20px;
    fill: #fff;
    stroke: #fff;
    cursor: pointer;
    color: #ffffff;
    position: relative;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0 5px;
}

.stream-controls .v-opts-box {
    display: block;
}

.vc-container .stream-controls.ant-form {
    background-color: transparent;
    padding: 0;
    margin: 0;
    display: flex;
}

.stream-controls .settings-box label,
.stream-controls .settings-box .ant-slider-mark {
    color: #ffffff;
    font-size: 11px;
}

.stream-controls .ant-slider-mark-text {
    color: rgba(255, 255, 255, 0.45);
}

.stream-controls .ant-slider-mark-text-active {
    color: rgba(255, 255, 255, 0.85);
}

.vc-container .stream-controls .ant-switch-checked {
    background-color: #1890ff;
}

.stream-controls .v-bitrate-opts .ant-form-item:nth-child(2) label {
    min-width: 78px;
    font-size: 11px;
    justify-content: flex-end;
}

.stream-controls .v-camera-opts .ant-form-item-label {
    min-width: 84px;
}

.stream-controls .v-fps-opts label,
.stream-controls .v-filter-opts label {
    min-width: 78px;
}

.vc-container .stream-controls .settings-box {
    position: static;
}

.vc-container .stream-controls .settings-box .settings-nav {
    left: auto;
    right: 0;
    width: 460px;
    height: auto;
    min-height: auto;
    bottom: 6.5px;
}

.vc-container .v-camera-opts .ant-form-item-control-input-content,
.vc-container .v-fps-opts .ant-form-item-control-input-content,
.vc-container .v-focus-opts .ant-form-item-control-input-content {
    text-align: left;
}

.vc-container .stream-controls .settings-nav-in {
    background-color: rgba(43, 51, 63, 0.7);
    margin-bottom: 27px;
    padding: 5px 15px;
}

.vc-container .stream-controls .ant-switch-inner svg {
    width: 1em;
    height: 1em;
}

.stream-controls .video-options {
    text-align: right;
    margin-top: -2px;
}

.stream-controls .video-options .v-rec-btn.ant-btn-sm,
.stream-controls .video-options .restart-btn.ant-btn-sm {
    margin-right: 15px;
}

.stream-controls .video-options .reset-btn.ant-btn-sm {
    margin-right: 0;
}
