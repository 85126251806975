.video-options {
    text-align: center;
}

.video-options .ant-card {
    margin-bottom: 5px;
}

.video-options .ant-card-body {
    padding: 0;
}

.video-options .stream-controls {
    padding: 15px 15px 5px 15px;
    text-align: left;
}

.video-options .stream-controls .v-bitrate-opts .ant-form-item:nth-child(2) label,
.video-options .stream-controls label {
    min-width: 85px;
}

.video-options .stream-controls label.ant-radio-wrapper {
    min-width: auto;
}

.video-options .stream-controls .v-bitrate-opts .ant-form-item:nth-child(2) label {
    justify-content: left;
}

.video-options .stream-controls .v-settings-button {
    margin-left: 0;
    font-weight: 200;
}

.video-options .v-rec-btn.ant-btn-sm,
.video-options .reset-btn.ant-btn-sm,
.video-options .restart-btn.ant-btn-sm {
    height: 20px;
    font-size: 10px;
    cursor: pointer;
    margin-right: 20px;
}

.video-options .v-rec-btn.ant-btn-sm .ant-badge,
.video-options .v-rec-btn.ant-btn-sm .ant-badge-status-text {
    font-size: 10px;
}

.reset-btn-tooltip {
    font-size: 10px !important;
}
