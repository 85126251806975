.stats-box .stats-title {
    height: 10px;
    font-size: 10px;
    margin-top: -10px;
}

.stats-box .ant-progress-text {
    font-size: 10px;
}

.stats-box .ant-progress-status-success .ant-progress-text {
    color: rgba(0, 0, 0, 0.85);
}

.stats-box .ant-progress-status-success .ant-progress-bg {
    background-color: #1890ff;
}
