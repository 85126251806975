.clock-container {
    width: 140px;
    overflow: hidden;
    transition: 0.2s;
    line-height: 1em;
    text-align: center;
}

.clock-container ul {
    padding: 0;
    margin: 5px 0 0 0;
}

.clock-container ul li {
    display: inline-block;
    font-size: 22px;
    text-align: center;
    font-family: monospace;
}

.clock-container .time-date {
    font-size: 10px;
    text-align: center;
}
