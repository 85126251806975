@import '~antd/dist/antd.css';

.logo {
    background: #002140;
}

.logo h1{
    color:#fff;
    line-height: 64px;
    font-size: 20px;
    text-align: center;
}

.loading-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
