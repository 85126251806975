.video-list .ant-list-item {
    padding: 0;
    border-bottom: none;
    margin-bottom: 5px;
    display: inline-block;
    margin-right: 5px;
}

.video-list .ant-list-item .ant-card-body {
    font-size: 12px;
    padding: 10px;
}

.video-list .ant-list-item .body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

.video-list .ant-list-item .body .ant-avatar {
    margin-right: 10px;
}

.video-list .ant-list-item .body strong {
    display: inline-block;
    width: 40px;
}

.video-list .ant-list-item .bottom {
    size: 14px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-top: 5px;
    padding: 0 5px;
}

.video-list .ant-card-actions .play-btn {
    color: #00000073;
    transition: color .3s;
}

.video-list .ant-card-actions .play-btn:hover {
    opacity: 0.7;
    transition: opacity .3s;
}

.video-list .ant-list-item .ant-card-actions > li {
    margin: 5px 0;
}
