.video-js .vjs-time-control {
    display: block;
}
.video-js .vjs-remaining-time {
    display: none;
}
.vjs-playback-rate .vjs-playback-rate-value {
    font-size: 10px;
    line-height: 32px;
}
.video-js .vjs-icon-cog:before {
    font-size: 14px;
}
.video-js .vjs-menu-content .zoom-reset-button {
    cursor: pointer;
}
.video-js .vjs-menu-content .zoom-slider-parent input {
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* Chromium */
    max-width: 100%;
}

.video-js video.vc-video-mirror {
    transform: scale(-1, 1);
}

.video-js .video-mirror-btn {
    color: white;
    cursor: pointer;
}

.video-js .video-mirror-btn .vc-mirror-icon-right {
    display: none;
}

.video-js .video-mirror-btn.vc-video-mirror .vc-mirror-icon-left {
    display: none;
}

.video-js .video-mirror-btn.vc-video-mirror .vc-mirror-icon-right {
    display: inline-block;
}
