.header .right-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header .stats-box {
    display: inline-block;
    width: 185px;
    max-width: 100%;
    margin-right: 15px;
}

.header-icons {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
}

.header .clock-container {
    display: inline-block;
}

.trigger {
    transition: color .3s;
}

.trigger:hover {
    color: #1890ff;
}

@media (max-width: 1000px) {
    .header .center-box {
        display: flex;
    }
}

@media (max-width: 700px) {
    .header .center-box .stats-title {
        display: none;
    }
}

@media (max-width: 450px) {
    .ant-layout-sider {
        display: none;
    }
}
