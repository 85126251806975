.timeline {
    overflow: hidden;
    width: 100%;
    position: relative;
}

.timeline img {
    height: 35px;
    cursor: pointer;
    padding: 0 50%;
}

.timeline img.timeline-animation {
    transition: transform 0.75s ease 0s;
}

.timeline::after {
    content: '';
    display: block;
    width: 2px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    border-radius: 1px;
}
